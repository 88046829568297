const appStage = process.env.REACT_APP_STAGE;

const configs = {
  dev: {
    apiUrl: 'https://dev.api.najotedu.uz',
    imgApiUrl: 'https://ntop-dev-main-db.s3.eu-central-1.amazonaws.com',
    landing: 'https://dev.web.najotedu.uz',
    captchaSiteKey: '6LehwAUpAAAAAEFVy0wVCx-xVFcZZ7IRvA4AF0LQ'
  },
  test: {
    apiUrl: 'https://test.api.najotedu.uz',
    imgApiUrl: 'https://ntop-test-main-db.s3.eu-central-1.amazonaws.com',
    landing: 'https://test.landing.najotedu.uz',
    captchaSiteKey: '6LddxAUpAAAAAC9s6PpwVMWD6c46Zr35jI2apUv5'
  },
  prod: {
    apiUrl: 'https://api.najottalim.uz',
    imgApiUrl: 'https://ntop-prod-main-db.s3.eu-central-1.amazonaws.com',
    landing: 'https://edfix.uz',
    captchaSiteKey: '6LdmxgUpAAAAAOgYaRzsNXRQtdN-QqPd5I3054Za'
  }
};

export const config = configs[appStage];
